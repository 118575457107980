import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Container,
  Button,
  Box,
  Toolbar,
  Drawer,
  IconButton,
  ImageList,
  ImageListItem,
} from "@material-ui/core";
import AppBar from "@mui/material/AppBar";
import { Row, Col } from "react-bootstrap";
import KioskPad from "./components/Keypad";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import PhoneInput from "react-phone-number-input";
import { NavBar } from "./navigation/NavBar";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { numberWithCommas } from "../../utils/numbersWithComma";
import moment from "moment";
import { Form, Input } from "../../components";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { removeExpiredItems, setTip } from "../../redux/cartReducer/actions";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { SingleDatePicker } from "react-dates";
import "rc-time-picker/assets/index.css";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import {
  setSelectedServiceOption,
  setOrderProcessingDate,
  setSelectedAddress,
} from "../../redux/publicReducer/actions";
import { useHistory, useRouteMatch } from "react-router-dom";
import Select from "react-select";
import {
  setModeOfPayment,
  setPromoCode,
  setTotalDiscount,
} from "../../redux/cartReducer/actions";
import { useCookies } from "react-cookie";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import DrawerRight from "features/eComm/components/DrawerRight";
import axios from "axios";

import PaymentNested from "./components/PaymentNested";
import LogoCenter from "./components/LogoCenter";
import BottomCheckOutNav from "features/eComm/components/BottomCheckOutNav";
import BlankSpace from "features/eComm/components/BlankSpace";
import { Backdrop } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { Modal } from "react-bootstrap";
import TextField from "@mui/material/TextField";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";

import ScrollToDown from "components/scroll/ScrollToDown";
import ScrollToUp from "components/scroll/ScrollToUp";
import Fab from "@mui/material/Fab";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import FontSizeChanger from "react-font-size-changer";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import BarTop from "features/eComm/components/BarTop";
import BarBottom from "features/eComm/components/BarBottom";

const serverId = process.env.REACT_APP_SERVER_ID;
const site = process.env.REACT_APP_SITE;
const siteId = process.env.REACT_APP_SITE_ID;
const storeId = process.env.REACT_APP_STORE_ID;
const siteImgSrc = process.env.REACT_APP_IMAGE_SRC;
const serviceCharge = process.env.REACT_APP_SERVICE_CHARGE;
const tableNumber = sessionStorage.getItem("table");
const enablePickup =
  tableNumber && tableNumber !== "none" ? 0 : process.env.REACT_APP_PICKUP;
const enableDineIN =
  tableNumber && tableNumber !== "none" ? 1 : process.env.REACT_APP_DINE_IN;
const enalbeDelivery =
  tableNumber && tableNumber !== "none" ? 0 : process.env.REACT_APP_DELIVERY;
const usePoints = process.env.REACT_APP_USE_POINTS;
const enableRevenueCenter = process.env.REACT_APP_ENABLE_REVENUE_CENTER;
const enableTip = process.env.REACT_APP_ENABLE_TIP;
const pickupDesc = process.env.REACT_APP_PICKUP_DESC;
const dineInDesc = process.env.REACT_APP_DINE_IN_DESC;
const deliveryDesc = process.env.REACT_APP_DELIVERY_DESC;
const enableKiosk = process.env.REACT_APP_ENABLE_EKIOSK;
const buttonColor = process.env.REACT_APP_BUTTON_COLOR;
const colorBackground = process.env.REACT_APP_BACKGROUND_COLOR;
const buttonText = process.env.REACT_APP_BUTTON_TEXT;
const siteMedSrc = process.env.REACT_APP_MEDIA_SRC;
const textHighlight = process.env.REACT_APP_BACKGROUND_COLOR;
const enableSL = parseInt(process.env.REACT_APP_ENABLE_SL);
const enableDiscounts = parseInt(process.env.REACT_APP_ENABLE_DISCOUNTS);
let enableNewUI = process.env.REACT_APP_NEWUI_LW;

//--Time Conditions--//
const startHour = 7;
const startTime = 420;
const endHour = 20;
const endTime = 1220;
const endMinutes = 0;

export const CheckoutSL = (props) => {
  const {
    orderProcessingDate,
    orderProcessingType,
    selectedAddress,
    user,
    loading,
    orderTimeSelectValue,
  } = useSelector((state) => state.public);
  const [siteSettings, setSiteSettings] = useState([]);
  const [openTips, setOpenTips] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [tax, setTax] = useState();
  const history = useHistory();
  const [openServiceOptions, setOpenServiceOptions] = useState(false);
  const [openUserCards, setOpenUserCards] = useState(false);
  const [tempPromoCode, setTempPromoCode] = useState("");
  const [pickupTimebyRevenueCenter, setPickupTimebyRevenueCenter] = useState(
    []
  );
  const [cookiePayment, setCookiePayment, removeCookiePayment] = useCookies([
    "payment",
  ]);
  const [showModal, setShowModal] = useState(false);
  const [disableCheckoutMessage, setDisableCheckoutMessage] = useState("");
  const [selectedTime, setSelectedTime] = useState(
    orderTimeSelectValue
      ? {
          label: orderTimeSelectValue,
          value: orderTimeSelectValue,
        }
      : null
  );
  const [updateTime, setUpdateTime] = useState("");
  const [pickupTimeLimits, setPickupTimeLimits] = useState();
  const [availableOrderTypes, setAvailableOrderTypes] = useState();
  const [itemsDiscount, setItemsDiscount] = useState({});
  const [itemsTotalDiscount, setItemsTotalDiscount] = useState(0);
  const hasDiscountsEnabled = enableDiscounts === 1;

  //onsreen keyboard
  const [keyboardVisible, setKeyboardVisible] = useState(false);
  const [keyboardVisibleName, setKeyboardVisibleName] = useState(false);
  const [messageToKitchen, setMessageToKitchen] = useState(
    sessionStorage.getItem("instuctions")
  );
  const [name, setName] = useState(sessionStorage.getItem("name"));

  const Swal = require("sweetalert2");

  const [loading1, setLoading] = useState(false);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);

  const {
    cartItems,
    totalPriceItems,
    tips,
    selectedPayment,
    promoCode,
    orderSession,
    totalDiscount,
  } = useSelector((state) => state.cart);

  const dispatch = useDispatch();

  const initialValues = {
    message: "",
  };

  const validationSchema = Yup.object({
    message: Yup.string(),
  });

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const { watch, setValue } = methods;
  const watchMessage = watch("message");

  const handleSubmit = (d) => {
    // console.log(d);
  };

  // =========== START ==========
  console.log(props.location, "Loki");
  // =========== END ===========

  // =========== START ==========
  console.log(orderProcessingType, "TYPE CHECKOUT SL");
  // =========== END ===========

  useEffect(() => {
    var sourceUpdateTime = new EventSource(
      "https://ecommv2.servingintel.com/Items/checkUpdate?site_id=" + siteId
    );
    sourceUpdateTime.onmessage = function (event) {
      var u_items = JSON.parse(event.data);
      //console.log(u_items[0].utime);
      if (u_items[0].time != "") {
        if (sessionStorage.getItem("update_date_time") !== u_items[0].time) {
          setUpdateTime(u_items[0].time);
          sessionStorage.setItem("update_date_time", u_items[0].time);
        }
      }
    };

    //Set default serviceOption
    if (enalbeDelivery == 1 && orderProcessingType === 2) {
      dispatch(setSelectedServiceOption(2));
    }
    if (enablePickup == 1 && orderProcessingType === 1) {
      dispatch(setSelectedServiceOption(1));
    }
    if (enableDineIN == 1 && orderProcessingType === 0) {
      dispatch(setSelectedServiceOption(0));
    }
  }, []);

  // Check if store is open
  useEffect(() => {
    const url =
      "https://ecommv2.servingintel.com/site/get-site-settings/" +
      serverId +
      "/" +
      siteId;
    axios
      .get(url)
      .then((response) => {
        setSiteSettings(response.data);
        if (response.data.OnOff < 1) {
          Swal.fire({
            title: "Store Message",
            text: response.data.eCommerceDisplayMessage,
            icon: "info",
            confirmButtonText: "OK",
          });
          history.push("/welcome");
        }
      })
      .catch((error) => {});
  }, [updateTime]);

  // Checkout buffer
  useEffect(() => {
    axios
      .get(
        `https://ecommv2.servingintel.com/site/is-checkout-disabled/${serverId}/${siteId}`
      )
      .then((response) => {
        if (response.data === 1) {
          Swal.fire({
            title: "Store Message",
            text: "Checkout is disabled at this moment.",
            icon: "info",
            confirmButtonText: "OK",
          });
          history.push("/");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [updateTime]);

  // Set kitchen message to sessionStorage
  const setKitchenMsg = (e) => {
    sessionStorage.setItem("instuctions", e.target.value);
  };

  const handleOnKeyPress = (button) => {
    if (button === "{enter}") {
      setKeyboardVisible(false);
    }
  };

  const handleOnChange = (value) => {
    setMessageToKitchen(value);
    sessionStorage.setItem("instuctions", value);
  };

  const handleOnKeyPressName = (button) => {
    if (button === "{enter}") {
      setKeyboardVisibleName(false);
    }
  };

  const handleOnChangeName = (value) => {
    setName(value);
    sessionStorage.setItem("name", value);
  };

  const updatePickupTimebyRevenueCenter = (pickup) => {
    if (enableRevenueCenter == 1) {
      setPickupTimebyRevenueCenter(pickup);
    }
  };

  // check for pinCodeOk in sessionStorage
  useEffect(() => {
    if (
      !sessionStorage.getItem("pinCodeOk") &&
      sessionStorage.getItem("pinCodeOk") !== 1
    ) {
      window.location.href = "/";
    }
  }, []);

  // set Tax
  useEffect(() => {
    //set date as current date
    let date = "";
    if (!orderProcessingDate) {
      date = moment();
    }

    const d = new Date();
    const hour = d.getHours(); //get current hour
    const min = d.getMinutes(); //get current min
    const cdate = d.getDate();
    const cyear = d.getFullYear();
    const cmonth = d.getMonth();

    if (
      d.getDate() == cdate &&
      d.getMonth() == cmonth &&
      d.getFullYear() == cyear &&
      (hour > endHour || (hour == endHour && min >= endMinutes))
    ) {
      //alert("Store is closed. You can order for next day");
      date = moment(d, "MM-DD-YYYY").add(1, "days");
    }

    if (!orderProcessingDate) {
      dispatch(setOrderProcessingDate(date));
    }
    const url =
      "https://ecommv2.servingintel.com/items/tax/" +
      siteId +
      "?server_id=" +
      serverId;
    axios
      .get(url)
      .then((response) => {
        setTax(
          parseFloat(parseFloat(response.data[0].TaxPercent / 100) / 100) / 100
        );
      })
      .catch((error) => {});
  }, []);

  // set cookie payment
  useEffect(() => {
    // console.log("pc:" + promoCode);
    if (cookiePayment?.payment?.id) {
      dispatch(setModeOfPayment(cookiePayment.payment));
    }
    if (cookiePayment?.tips) {
      dispatch(setTip(cookiePayment.tips));
    }
    if (cookiePayment.message) {
      setValue("message", cookiePayment.message);
    }
    if (cookiePayment.promoCode) {
      dispatch(setPromoCode(cookiePayment.promoCode));
      setTempPromoCode(cookiePayment.promoCode);
    }
  }, []);

  // get cookie
  const getCookie = (key) => {
    var keyValue = document.cookie.match("(^|;) ?" + key + "=([^;]*)(;|$)");
    return keyValue ? keyValue[2] : null;
  };

  // set sic_address from cookie
  useEffect(() => {
    // if (user?.address) {
    //   dispatch(setSelectedAddress(user.address));
    // }
    const address = getCookie("sic_address");
    if (address) {
      dispatch(setSelectedAddress(JSON.parse(address)));
    }
  }, []);

  useEffect(() => {
    if (selectedPayment) {
      setCookiePayment("payment", selectedPayment, { path: "/" });
    } else {
      removeCookiePayment("payment", { path: "/" });
    }
    if (tips) {
      setCookiePayment("tips", tips, { path: "/" });
    } else {
      removeCookiePayment("tips", { path: "/" });
    }

    if (promoCode) {
      setCookiePayment("promoCode", promoCode, { path: "/" });
    } else {
      removeCookiePayment("promoCode", { path: "/" });
    }

    if (watchMessage) {
      setCookiePayment("message", watchMessage, { path: "/" });
    } else {
      removeCookiePayment("message", { path: "/" });
    }
  }, [selectedPayment, tips, watchMessage, promoCode]);

  useEffect(() => {
    if (tempPromoCode || promoCode) {
      setDiscount(0);
      setDiscountPercentage(0);
      const tpc =
        tempPromoCode == ""
          ? promoCode != ""
            ? promoCode
            : "coupon"
          : promoCode;
      const url =
        "https://ecommv2.servingintel.com/payment/coupon/" +
        serverId +
        "/" +
        siteId +
        "/" +
        tpc;
      //const url = "/servingintel_api/public/items/all";
      axios
        .get(url)
        .then((response) => {
          if (response.data.length == 0) {
            dispatch(setPromoCode(""));
            //alert("Invalid Coupon");
          } else {
            const dept = response.data.department;
            const percent = response.data.discount_percent;
            const discount = response.data.discount;
            const subTotal = response.data.subTotal;

            setDiscountPercentage(parseFloat(percent * 100));

            if (dept !== "") {
              //by deparment
              let totalDiscountPrice = 0;
              cartItems.forEach((item) => {
                if (item.Department == dept) {
                  totalDiscountPrice =
                    parseFloat(totalDiscountPrice) +
                    parseFloat((item.DefaultPrice / 100) * item.quantity);
                }
              });

              if (totalDiscountPrice > 0) {
                //applicable items
                if (discount > 0) {
                  // direct amount discount
                  if (totalPriceItems >= subTotal) {
                    setDiscount(parseFloat(discount));
                  } else {
                    //dispatch(setPromoCode(""));
                    //alert("Total item(s) price did not meet the minimum required for this coupon which is $"+subTotal+".")
                  }
                } else {
                  //percentage discount
                  if (totalPriceItems >= subTotal) {
                    setDiscount(parseFloat(totalDiscountPrice * percent));
                  } else {
                    //dispatch(setPromoCode(""));
                    //alert("Total item(s) price did not meet the minimum required for this coupon which is $"+subTotal+".")
                  }
                }
              } else {
                dispatch(setPromoCode(""));
                //alert("Coupon is not applicable to the existing Check Item(s).")
              }
            } else {
              //all items
              setDiscount(parseFloat(totalPriceItems * percent));
            }
          }
        })
        .catch((error) => {});
    }
  }, [totalPriceItems]);

  useEffect(() => {
    dispatch(setTotalDiscount(discount));
  }, [discount]);

  const applyCoupon = () => {
    dispatch(setPromoCode(tempPromoCode));
    setDiscount(0);
    setDiscountPercentage(0);

    const url =
      "https://ecommv2.servingintel.com/payment/coupon/" +
      serverId +
      "/" +
      siteId +
      "/" +
      tempPromoCode;
    //const url = "/servingintel_api/public/items/all";
    axios
      .get(url)
      .then((response) => {
        if (response.data.length == 0) {
          dispatch(setPromoCode(""));
          alert("Invalid Coupon");
        } else {
          const dept = response.data.department;
          const percent = response.data.discount_percent;
          const discount = response.data.discount;
          const subTotal = response.data.subTotal;

          setDiscountPercentage(parseFloat(percent * 100));

          if (dept !== "") {
            //by deparment
            let totalDiscountPrice = 0;
            cartItems.forEach((item) => {
              if (item.Department == dept) {
                totalDiscountPrice =
                  parseFloat(totalDiscountPrice) +
                  parseFloat((item.DefaultPrice / 100) * item.quantity);
              }
            });

            if (totalDiscountPrice > 0) {
              //applicable items
              if (discount > 0) {
                // direct amount discount
                if (totalPriceItems >= subTotal) {
                  setDiscount(parseFloat(discount));
                } else {
                  dispatch(setPromoCode(""));
                  alert(
                    "Total item(s) price did not meet the minimum required for this coupon which is $" +
                      subTotal +
                      "."
                  );
                }
              } else {
                //percentage discount
                if (totalPriceItems >= subTotal) {
                  setDiscount(parseFloat(totalDiscountPrice * percent));
                } else {
                  dispatch(setPromoCode(""));
                  alert(
                    "Total item(s) price did not meet the minimum required for this coupon which is $" +
                      subTotal +
                      "."
                  );
                }
              }
            } else {
              dispatch(setPromoCode(""));
              alert("Coupon is not applicable to the existing Check Item(s).");
            }
          } else {
            //all items
            setDiscount(parseFloat(totalPriceItems * percent));
          }
        }
      })
      .catch((error) => {});
  };

  // get pickup limit settings
  useEffect(() => {
    const menuName = props.location.state && props.location.state.menuName;

    const currentMenuName = menuName != "" ? menuName : "none";
    const url =
      "https://ecommv2.servingintel.com/items/pickup-time-limit/" +
      serverId +
      "/" +
      siteId +
      "/" +
      currentMenuName +
      "/" +
      orderProcessingType;
    console.log(url);

    axios
      .get(url)
      .then((response) => {
        setPickupTimeLimits(response.data);
      })
      .catch((error) => {});
  }, [orderProcessingType]);

  // get available order types
  useEffect(() => {
    const revenueCenter =
      props.location.state && props.location.state.revenueCenter
        ? props.location.state.revenueCenter
        : "none";
    const menuName =
      props.location.state && props.location.state.menuName
        ? props.location.state.menuName
        : "none";
    const currentMenuName = menuName != "" ? menuName : "none";

    const url =
      "https://ecommv2.servingintel.com/items/available-order-types/" +
      serverId +
      "/" +
      siteId +
      "/" +
      revenueCenter +
      "/" +
      currentMenuName;
    axios
      .get(url)
      .then((response) => {
        if (response.data) {
          let orderTypes = [];

          // Map over response.data and push OrderType to orderTypes array
          console.log(response.data, "data");
          response.data.forEach((item) => orderTypes.push(item.OrderType));

          // Create a Set from orderTypes to remove duplicates and convert it back to an array
          const uniqueOrderTypes = Array.from(new Set(orderTypes));
          // Set the state with the array of unique order types
          setAvailableOrderTypes(uniqueOrderTypes);

          if (orderProcessingType === 0) {
            if (
              uniqueOrderTypes.includes("Dine In") &&
              orderProcessingType === 0
            ) {
              dispatch(setSelectedServiceOption(0));
            } else if (
              uniqueOrderTypes.includes("Pick Up") &&
              orderProcessingType === 1
            ) {
              dispatch(setSelectedServiceOption(1));
            } else {
              dispatch(setSelectedServiceOption(2));
            }
          }
        }
      })
      .catch((error) => {});
  }, [orderProcessingType]);

  // update item quantity
  const updateQuantity = (item, change) => {
    const newCartItems = cartItems
      .map((cartItem) => {
        if (cartItem.ItemID === item.ItemID) {
          const newQuantity = cartItem.quantity + change;
          return { ...cartItem, quantity: newQuantity };
        }
        return cartItem;
      })
      .filter((cartItem) => cartItem.quantity > 0);

    dispatch({
      type: "SET_CART",
      payload: newCartItems,
    });
  };

  const calculateDiscountedItemPrice = (item) => {
    const discountRate = getDiscountRate(item);
    const totalPrice = parseFloat((item.DefaultPrice / 100) * item.quantity);
    const discountedPrice = totalPrice * parseFloat(discountRate / 100);

    return discountedPrice;
  };

  const getDiscountRate = (item) => {
    const itemDiscount = parseFloat(item.Discount);
    const customerDiscount = parseFloat(
      sessionStorage.getItem("customerDiscount")
    );

    return itemDiscount > 0
      ? itemDiscount
      : !isNaN(customerDiscount)
        ? customerDiscount
        : 0;
  };

  // Store discounts of each item into obj
  useEffect(() => {
    if (hasDiscountsEnabled) {
      const updatedItemsDiscount = {};

      cartItems.forEach((item) => {
        updatedItemsDiscount[item.ItemName] = numberWithCommas(
          calculateDiscountedItemPrice(item)
        );
      });

      setItemsDiscount(updatedItemsDiscount);
    }
  }, [cartItems, hasDiscountsEnabled]);

  // Add all discounts from each item in itemsDiscont and store all in totalDiscount
  useEffect(() => {
    if (hasDiscountsEnabled) {
      const itemsTotalDiscount = Object.values(itemsDiscount).reduce(
        (sum, value) => sum + parseFloat(value),
        0
      );

      setItemsTotalDiscount(itemsTotalDiscount);
    }
  }, [itemsDiscount, hasDiscountsEnabled]);
  console.log(
    orderProcessingDate,
    orderProcessingType,
    orderTimeSelectValue,
    orderProcessingDate && orderTimeSelectValue
  );
  return (
    <React.Fragment>
      <div
        className={enableKiosk === "0" ? "HeaderImg" : "HeaderImgeKiosk"}
        style={{
          backgroundImage:
            enableKiosk === "0"
              ? `url(${siteMedSrc}ContactSliderImg.png)`
              : null,
          color: enableKiosk === "0" ? buttonText : null,
          height: enableKiosk === "0" ? "220px" : null,
        }}
      >
        <Container>
          <Row>
            <Col xs={12}>
              <h2
                style={{
                  color: enableKiosk === "0" ? textHighlight : null,
                }}
                className={
                  enableKiosk === "0"
                    ? "blankspace center"
                    : "padding-bottom-20 center black"
                }
              >
                Your Payment and Billing Details
              </h2>
            </Col>
          </Row>
        </Container>
      </div>
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{"Checkout: Accept Payments Online"}</title>
          <link rel="canonical" href="/checkout" />
          <meta
            name="description"
            content="Secure, quick and easy to use payments integration. Fast settlement."
          />
        </Helmet>
      </HelmetProvider>
      {parseInt(enableKiosk) === 0 && (
        <FontSizeChanger
          targets={["#target .content"]}
          onChange={(element, newValue, oldValue) => {
            console.log(element, newValue, oldValue);
          }}
          options={{
            stepSize: 2,
            range: 5,
          }}
          customButtons={{
            up: (
              <AppBar
                position="fixed"
                sx={{
                  width: "95px",
                  boxShadow: 0,
                  top: "auto",
                  bottom: 237.5,
                  backgroundColor: "transparent",
                  marginBottom: "50px",
                }}
              >
                <Toolbar>
                  <Box sx={{ flexGrow: 1 }} />
                  <IconButton>
                    <Fab
                      color="primary"
                      aria-hidden="true"
                      sx={{ backgroundColor: "#367c2b" }}
                    >
                      <AddCircleOutlineIcon />
                    </Fab>
                  </IconButton>
                </Toolbar>
              </AppBar>
            ),
            down: (
              <AppBar
                position="fixed"
                sx={{
                  width: "95px",
                  boxShadow: 0,
                  top: "auto",
                  bottom: 180,
                  backgroundColor: "transparent",
                  marginBottom: "50px",
                }}
              >
                <Toolbar>
                  <Box sx={{ flexGrow: 1 }} />
                  <IconButton>
                    <Fab
                      color="primary"
                      aria-hidden="true"
                      sx={{ backgroundColor: "#765F00" }}
                    >
                      <RemoveCircleOutlineIcon />
                    </Fab>
                  </IconButton>
                </Toolbar>
              </AppBar>
            ),
          }}
        />
      )}
      {/* {enableKiosk === "0" ? <LogoCenter /> : null} */}
      <DrawerRight />
      <Container
        id="target"
        style={{
          position: "relative",
          /*overflowY: "auto",*/
          marginTop: "2.5rem",
          backgroundColor: "transparent",
        }}
      >
        <Grid style={{ marginLeft: "0.7rem" }}>
          <Button
            style={{
              textTransform: "none",
              color: "gray",
              marginTop: "15px",
              backgroundColor: buttonColor,
              color: buttonText,
              borderColor: buttonColor,
              fontSize: enableKiosk === "0" ? "1.25rem" : "1.125rem",
            }}
            onClick={() =>
              enableRevenueCenter == 1
                ? history.push("/welcome")
                : history.push("/menu")
            }
          >
            Add More
          </Button>

          {cartItems && cartItems.length > 0 && (
            <Button
              style={{
                marginLeft: "1rem",
                textTransform: "none",
                fontSize: "1.5rem",
                backgroundColor: buttonColor,
                color: buttonText,
                borderColor: buttonColor,
                marginTop: "15px",
              }}
              onClick={() =>
                Swal.fire({
                  title: "Store Message",
                  text: "This will remove all items from current order, do you wish to proceed?",
                  icon: "info",
                  fontSize: "1.5rem",
                  showCancelButton: true, // This will show the cancel button
                  confirmButtonText: "OK",
                  cancelButtonText: "Cancel",
                }).then((result) => {
                  if (result.isConfirmed) {
                    dispatch({
                      type: "SET_CART",
                      payload: [],
                    });
                    history.push(
                      parseInt(enableRevenueCenter) === 1 ? "/welcome" : "/menu"
                    );
                  }
                })
              }
            >
              Clear Cart
            </Button>
          )}
          <Typography
            className="content"
            style={{
              fontWeight: 700,
              paddingTop: "20px",
              fontSize: enableKiosk === "0" ? "1.25rem" : "1.125rem",
            }}
            gutterBottom
          >
            My Order
          </Typography>
        </Grid>
        <Box height="1rem" />
        <Grid container spacing={1}>
          {cartItems.length === 0 ? (
            <Typography
              style={{
                fontSize: enableKiosk === "0" ? "1.25rem" : "1.125rem",
                textAlign: "center",
                width: "100%",
                color: "#bebebe",
              }}
              variant="h6"
            >
              NO ITEMS IN CHECK!
            </Typography>
          ) : (
            cartItems.map((item) => {
              const modifiers = item.modifiers.sort(
                (a, b) => parseInt(a.ModifierIndex) - parseInt(b.ModifierIndex)
              );

              const discountRate = hasDiscountsEnabled && getDiscountRate(item);
              const discountedPrice =
                hasDiscountsEnabled && calculateDiscountedItemPrice(item);
              const originalPrice = (item.DefaultPrice / 100) * item.quantity;

              return (
                <React.Fragment key={item.ItemID}>
                  <TableContainer
                    id="checkoutTable"
                    component={Paper}
                    style={{ backgroundColor: "#EAEAEA" }}
                  >
                    <Table
                      className="tableOrderList"
                      style={{ tableLayout: "fixed" }}
                    >
                      <TableHead>
                        <TableRow className="checkoutMobileBorder">
                          <TableCell width="150px" className="checkcell01">
                            <img
                              // alt={item.FileName}
                              height="100%"
                              width="100%"
                              // title={item.FileName}
                              loading="lazy"
                              src={
                                item.FileName && item.FileName.length > 0
                                  ? siteImgSrc + item.FileName
                                  : null
                              }
                              style={{ borderRadius: "10px" }}
                            />
                          </TableCell>

                          <TableCell
                            id="checkoutItemName"
                            className="checkcell02"
                            colSpan={6}
                            style={{ width: "30%" }}
                          >
                            <Typography
                              component="h2"
                              variant="h5"
                              style={{
                                fontWeight: 700,
                                fontSize:
                                  enableKiosk === "0" ? "1.25rem" : "1.125rem",
                              }}
                              gutterBottom
                            >
                              {item.ItemName}
                            </Typography>
                            {item.modifiers && item.modifiers.length > 0 ? (
                              <Typography variant="h5">Add-On:</Typography>
                            ) : null}
                            {item.modifiers
                              ? modifiers.map((mod) => (
                                  <>
                                    <Typography component="span" variant="h5">
                                      {mod.ItemDescription} - ${" "}
                                      {numberWithCommas(mod.DefaultPrice / 100)}
                                      <br></br>
                                    </Typography>
                                  </>
                                ))
                              : null}
                          </TableCell>

                          <TableCell align="center" style={{ width: "40%" }}>
                            <Grid
                              container
                              justifyContent="start"
                              className="tableRemoveAdd"
                            >
                              <Box
                                borderRadius="4px"
                                display="flex"
                                alignItems="center"
                                padding={
                                  enableKiosk === "0" ? ".5rem" : ".25rem"
                                }
                                style={{
                                  backgroundColor: buttonColor,
                                  color: buttonText,
                                  borderColor: buttonColor,
                                  border: "none",
                                }}
                              >
                                {item.comments != "Delivery Charge" &&
                                item.comments != "ItemTagMenu" ? (
                                  <>
                                    <Button
                                      style={{
                                        minWidth: "40px",
                                        backgroundColor: buttonColor,
                                        color: buttonText,
                                        borderColor: buttonColor,
                                        border: "none",
                                      }}
                                    >
                                      <RemoveIcon
                                        style={{
                                          backgroundColor: buttonColor,
                                          color: buttonText,
                                          borderColor: buttonColor,
                                          border: "none",
                                        }}
                                        fontSize={
                                          enableKiosk === "0"
                                            ? "1.25rem"
                                            : "1.125rem"
                                        }
                                        onClick={() => updateQuantity(item, -1)}
                                      />
                                    </Button>
                                    <Box
                                      style={{
                                        backgroundColor: buttonColor,
                                        color: buttonText,
                                        borderColor: buttonColor,
                                        border: "none",
                                      }}
                                      fontSize={
                                        enableKiosk === "0"
                                          ? "1.25rem"
                                          : "1.125rem"
                                      }
                                      margin={
                                        enableKiosk === "0" ? "0" : "0 1rem"
                                      }
                                    >
                                      {item.quantity}
                                    </Box>
                                    <Button
                                      style={{
                                        minWidth: "40px",
                                        backgroundColor: buttonColor,
                                        color: buttonText,
                                        borderColor: buttonColor,
                                        border: "none",
                                      }}
                                    >
                                      <AddIcon
                                        style={{
                                          backgroundColor: buttonColor,
                                          color: buttonText,
                                          borderColor: buttonColor,
                                          border: "none",
                                        }}
                                        fontSize={
                                          enableKiosk === "0"
                                            ? "1.25rem"
                                            : "1.125rem"
                                        }
                                        onClick={() => updateQuantity(item, 1)}
                                      />
                                    </Button>
                                  </>
                                ) : null}
                              </Box>
                            </Grid>
                          </TableCell>

                          <TableCell align="right" style={{ width: "30%" }}>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                width: "100%",
                              }}
                            >
                              <Box
                                className="mb-2 flex items-center"
                                sx={{ justifyContent: "flex-end" }}
                              >
                                <Box className="flexRowCompact">
                                  <Box className="primaryItemDisplay">
                                    <Box
                                      className="flexTruncateBaseline"
                                      sx={{
                                        fontWeight: 700,
                                        fontSize:
                                          enableKiosk === "0"
                                            ? "1.25rem"
                                            : "1.125rem",
                                      }}
                                    >
                                      <span>$</span>
                                      <span>
                                        {usePoints === 1
                                          ? numberWithCommas(
                                              originalPrice - discountedPrice
                                            ) + "pts"
                                          : numberWithCommas(
                                              originalPrice - discountedPrice
                                            )}
                                      </span>
                                    </Box>
                                  </Box>

                                  {discountRate > 0 && (
                                    <Box className="mb-15">
                                      <Box className="discountTag white">
                                        <span aria-label={`-${discountRate}%`}>
                                          -{discountRate}%
                                        </span>
                                      </Box>

                                      <Box className="originalPriceTag">
                                        <Box className="flexTruncateBaseline">
                                          <span>$</span>
                                          <span className="truncate">
                                            {numberWithCommas(originalPrice)}
                                          </span>
                                        </Box>
                                      </Box>
                                    </Box>
                                  )}
                                </Box>
                              </Box>
                            </Box>
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        <TableRow style={{ borderBottom: "1px solid #ebebeb" }}>
                          <TableCell></TableCell>

                          <TableCell className="checkcell02">
                            {item.modifiers
                              ? modifiers.map((mod) => (
                                  <>
                                    <Typography
                                      style={{
                                        fontWeight: 700,
                                        fontSize:
                                          enableKiosk === "0"
                                            ? "1.25rem"
                                            : "1rem",
                                      }}
                                    >
                                      {mod.ItemDescription}
                                    </Typography>
                                  </>
                                ))
                              : null}
                          </TableCell>
                          <TableCell
                            align="center"
                            className="checkcell03"
                          ></TableCell>
                          <TableCell align="center" className="checkcell04">
                            {item.modifiers
                              ? modifiers.map((mod) => (
                                  <>
                                    <Typography
                                      style={{
                                        fontWeight: 700,
                                        fontSize:
                                          enableKiosk === "0"
                                            ? "1.25rem"
                                            : "1rem",
                                      }}
                                    >
                                      ${" "}
                                      {numberWithCommas(mod.DefaultPrice / 100)}
                                    </Typography>
                                  </>
                                ))
                              : null}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </React.Fragment>
              );
            })
          )}
        </Grid>
        <Box height="1rem" />

        <TableContainer
          id="checkoutTotal"
          component={Paper}
          className="checkoutMobileFlexTotal"
        >
          {/* <Table className="checkoutMobileFlexTotalInner"> */}
          <Table>
            <TableBody>
              <TableRow>
                <TableCell colSpan={3} style={{ width: "17%" }}>
                  <Typography
                    style={{
                      fontWeight: 700,
                      fontSize: enableKiosk === "0" ? "1.25rem" : "1.125rem",
                    }}
                  >
                    Subtotal
                  </Typography>
                </TableCell>
                <TableCell colSpan={1} style={{ width: "auto" }}></TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="right" style={{ width: "30%" }}>
                  <Typography
                    style={{
                      fontWeight: 700,
                      fontSize: enableKiosk === "0" ? "1.25rem" : "1.125rem",
                    }}
                  >
                    $
                    {numberWithCommas(
                      parseFloat(totalPriceItems - itemsTotalDiscount)
                    )}
                  </Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan={3} style={{ width: "auto" }}>
                  <Typography
                    style={{
                      fontWeight: 700,
                      fontSize: enableKiosk === "0" ? "1.25rem" : "1.125rem",
                    }}
                  >
                    Sales Tax ({Number((tax * 100).toFixed(2))}%)
                  </Typography>
                </TableCell>
                <TableCell colSpan={1} style={{ width: "auto" }}></TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="right" style={{ width: "auto" }}>
                  <Typography
                    style={{
                      fontWeight: 700,
                      fontSize: enableKiosk === "0" ? "1.25rem" : "1.125rem",
                    }}
                  >
                    $
                    {numberWithCommas(
                      Math.round(
                        (totalPriceItems - itemsTotalDiscount) * tax * 100 +
                          1e-10
                      ) / 100
                    )}
                  </Typography>
                </TableCell>
              </TableRow>

              <TableRow></TableRow>

              {enableTip == 1 ? (
                <TableRow>
                  <TableCell colSpan={4}>
                    <Typography
                      style={{
                        fontWeight: 700,
                        fontSize: enableKiosk === "0" ? "1.25rem" : "1.125rem",
                      }}
                    >
                      Tip
                      <Button
                        style={{
                          textTransform: "none",
                          marginLeft: "10px",
                          color: "gray",
                          fontSize: "0.8rem",
                          backgroundColor: buttonColor,
                          borderColor: buttonColor,
                          border: "1px solid",
                          borderRadius: "6px",
                          padding: "5px",
                          alignItems: "center",
                          color: buttonText,
                        }}
                        onClick={() => setOpenTips(true)}
                      >
                        Change
                      </Button>
                    </Typography>
                  </TableCell>

                  <TableCell align="center"></TableCell>
                  <TableCell align="right" style={{ width: "30%" }}>
                    <Typography
                      style={{
                        fontWeight: 700,
                        fontSize: enableKiosk === "0" ? "1.25rem" : "1.125rem",
                      }}
                    >
                      ${numberWithCommas(tips)}
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : null}

              <TableRow
                className="amountDueMobile"
                style={{ marginTop: "10px", paddingTop: "10px" }}
              >
                <TableCell colSpan={5} style={{ width: "auto" }}>
                  <Typography
                    component="h3"
                    variant="h4"
                    style={{
                      fontWeight: 700,
                      fontSize: enableKiosk === "0" ? "1.25rem" : "1.125rem",
                    }}
                  >
                    Amount Due
                  </Typography>
                </TableCell>
                <TableCell align="right" style={{ width: "auto" }}>
                  <Typography
                    component="h3"
                    variant="h4"
                    style={{
                      fontWeight: 700,
                      fontSize: enableKiosk === "0" ? "1.25rem" : "1.125rem",
                    }}
                  >
                    $
                    {numberWithCommas(
                      parseFloat(totalPriceItems - itemsTotalDiscount) +
                        parseFloat(
                          Math.round(
                            (totalPriceItems - itemsTotalDiscount) * tax * 100 +
                              1e-10
                          ) / 100
                        )
                    )}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <BlankSpace />
        <br />
        {/*******Start Message to Kitchen *******/}
        {parseInt(enableKiosk) === 1 ? (
          <Grid container>
            <Typography
              gutterBottom
              style={{
                fontWeight: 700,
                fontSize: enableKiosk === "0" ? "1.25rem" : "1.125rem",
                marginTop: enableKiosk === "0" ? "1.125rem" : "2rem",
              }}
            >
              * Enter your name
            </Typography>
            <Grid item xs={12}>
              <div>
                <TextField
                  className="eKioskInput"
                  style={{
                    width: "100%",
                  }}
                  onChange={(e) => handleOnChangeName(e.target.value)}
                  onFocus={() => setKeyboardVisibleName(true)}
                  variant="outlined"
                  name="name"
                  value={name}
                  placeholder=""
                  autoComplete="off"
                />

                {keyboardVisibleName && (
                  <Keyboard
                    onKeyPress={(button) => handleOnKeyPressName(button)}
                    onChange={(input) => handleOnChangeName(input)}
                    inputName="name"
                  />
                )}
              </div>
            </Grid>
            <Typography
              gutterBottom
              style={{
                fontWeight: 700,
                fontSize: enableKiosk === "0" ? "1.25rem" : "1.125rem",
                marginTop: enableKiosk === "0" ? "1.125rem" : "2rem",
              }}
            >
              Message to Kitchen
            </Typography>
            <Grid item xs={12}>
              <div>
                <TextField
                  className="eKioskInput"
                  style={{
                    width: "100%",
                  }}
                  onChange={(e) => handleOnChange(e.target.value)}
                  onFocus={() => setKeyboardVisible(true)}
                  variant="outlined"
                  name="message"
                  value={messageToKitchen}
                  placeholder=""
                  autoComplete="off"
                />

                {keyboardVisible && (
                  <Keyboard
                    onKeyPress={(button) => handleOnKeyPress(button)}
                    onChange={(input) => handleOnChange(input)}
                    inputName="message"
                  />
                )}
              </div>
            </Grid>
          </Grid>
        ) : (
          <Grid container>
            <Typography
              gutterBottom
              style={{
                fontWeight: 700,
                fontSize: enableKiosk === "0" ? "1.25rem" : "1.125rem",
                marginTop: enableKiosk === "0" ? "1.125rem" : "2rem",
                paddingLeft: "1rem",
              }}
            >
              Message to Kitchen
            </Typography>
            <Grid item xs={12}>
              <Form provider={methods} onSubmit={handleSubmit}>
                <Input
                  className={enableKiosk === "1" ? "eKioskInput" : null}
                  onChange={setKitchenMsg}
                  variant="outlined"
                  name="message"
                  placeholder="Enter special instructions"
                  autoComplete="off"
                  border="1px solid #bebebe"
                />
              </Form>
            </Grid>
          </Grid>
        )}
        {/*******End Message to Kitchen *******/}
      </Container>

      <TipsDrawerSL open={openTips} setOpen={setOpenTips} />
      <SelectCreditCardDrawerSL
        open={openUserCards}
        setOpen={setOpenUserCards}
        tax={tax}
        itemsTotalDiscount={itemsTotalDiscount}
        discount={discount}
      />
      {parseInt(enableKiosk) === 0 && (
        <ServiceOptionsDrawerSL
          open={openServiceOptions}
          menuName={props.location.state && props.location.state.menuName}
          pickupTimeLimits={pickupTimeLimits}
          availableOrderTypes={availableOrderTypes}
          siteSettings={siteSettings}
          setOpen={setOpenServiceOptions}
          updatePickupTimebyRevenueCenter={updatePickupTimebyRevenueCenter.bind(
            this
          )}
          setSelectedTime={setSelectedTime}
        />
      )}
      <Container
        id="target"
        style={{
          position: "relative",
          backgroundColor: "transparent",
        }}
      >
        <PaymentSelectorSL
          setOpenUserCards={setOpenUserCards}
          tax={tax}
          pickupTimebyRevenueCenter={pickupTimebyRevenueCenter}
          itemsTotalDiscount={itemsTotalDiscount}
          getDiscountRate={getDiscountRate}
          hasDiscountsEnabled={hasDiscountsEnabled}
          selectedTime={selectedTime}
        />
        <BlankSpace />
      </Container>
      {enableKiosk === "0" || enableSL === "1" ? (
        <BottomCheckOutNav
          sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
        />
      ) : null}
      {/* <ChangeTextSize /> */}
      {enableKiosk === "0" || enableSL === "1" ? <ScrollToDown /> : null}
      {enableKiosk === "0" || enableSL === "1" ? <ScrollToUp /> : null}
      {enableNewUI === "1" ? (
        <>
          <BarTop />
          <BarBottom />
        </>
      ) : null}
    </React.Fragment>
  );
};

export const ServiceOptionsDrawerSL = ({
  open,
  menuName,
  pickupTimeLimits,
  availableOrderTypes,
  siteSettings,
  setOpen,
  updatePickupTimebyRevenueCenter,
  setSelectedTime,
}) => {
  const dispatch = useDispatch();
  const [date, setDate] = useState(moment());
  const [focused, setFocused] = useState(false);
  const [timeConstant, setTimeConstant] = useState([]);
  const [pickupCounter, setPickupCounter] = useState([]);
  const [pickupTimebyRevenueCenter, setPickupTimebyRevenueCenter] = useState(
    []
  );

  //const [time, setTime] = useState(moment().add("20", "mins"));
  const { orderProcessingDate, orderProcessingType, orderTimeSelectValue } =
    useSelector((state) => state.public);
  const history = useHistory();

  const { cartItems } = useSelector((state) => state.cart);
  //group cart items by revCenter
  const revenueCenters = cartItems.reduce(function (rv, x) {
    (rv[x["RevenueCenter"] || x["revenueCenter"]] =
      rv[x["RevenueCenter"] || x["revenueCenter"]] || []).push(x);
    return rv;
  }, {});

  const updateTime = (e) => {
    // dispatch(setOrderProcessingDate(moment()))
    let date;
    if (Date.parse(orderProcessingDate) > 0) {
      const timeAndDate = moment(
        moment(orderProcessingDate).format("MMMM DD, YYYY") + " " + e.value
      );
      //console.log(timeAndDate, "TIME AND DATE 1234");
      date = timeAndDate;
      dispatch(setOrderProcessingDate(timeAndDate));
    } else {
      //alert("Invalid Date");
      const today = new Date();
      const defautTimeAndDate = moment(
        moment(orderProcessingDate).format("MMMM DD, YYYY") + " " + today
      );
      date = defautTimeAndDate;
    }

    // If these fields doesn't have value update the order processing date
    if (!orderProcessingDate && !orderProcessingType && !orderTimeSelectValue) {
      dispatch(setOrderProcessingDate(date));
    }

    //For revenue center enabled
    if (enableRevenueCenter == 1) {
      const revPickup = { revenueCenter: e.rev, pickup: e.value };

      if (pickupTimebyRevenueCenter && pickupTimebyRevenueCenter.length > 0) {
        setPickupTimebyRevenueCenter(
          pickupTimebyRevenueCenter.filter((i) => i.revenueCenter != e.rev)
        );
      }

      setPickupTimebyRevenueCenter((pickupTimebyRevenueCenter) => [
        ...pickupTimebyRevenueCenter,
        revPickup,
      ]);
    }
  };

  const addDeliveryCharge = async () => {
    try {
      const url = `https://ecommv2.servingintel.com/items/item-charge-delivery/${serverId}/${siteId}`;
      const response = await axios.get(url);

      if (response.data && response.data.length > 0) {
        const itemChargeDelivery = response.data[0];

        if (cartItems.length > 0) {
          const cleanItemChargeDelivery = cartItems.filter(
            (item) => item.comments !== "Delivery Charge"
          );

          const deliveryChargeItem = {
            ...itemChargeDelivery,
            quantity: 1,
            modifiers: [],
            comments: "Delivery Charge",
          };

          dispatch({
            type: "SET_CART",
            payload: [...cleanItemChargeDelivery, deliveryChargeItem],
          });
        }
      }
    } catch (error) {
      console.error("Error adding delivery charge:", error);
    }
  };

  const removeDeliveryCharge = () => {
    const cartItemsRemoveCharge = cartItems.filter(
      (i) => i.comments !== "Delivery Charge"
    );

    dispatch({
      type: "SET_CART",
      payload: cartItemsRemoveCharge,
    });
  };

  useEffect(() => {
    if (orderProcessingType != 2) {
      removeDeliveryCharge();
    }
  }, []);

  useEffect(() => {
    updatePickupTimebyRevenueCenter(pickupTimebyRevenueCenter);
  }, [pickupTimebyRevenueCenter]);

  //get pickup limit for today
  useEffect(() => {
    const baseUrl =
      enableRevenueCenter == 1
        ? "https://ecommv2.servingintel.com/items/pickup-orders-today-revenue-center/"
        : "https://ecommv2.servingintel.com/items/pickup-orders-today/";

    const url = baseUrl + serverId + "/" + siteId;
    axios
      .get(url)
      .then((response) => {
        setPickupCounter(response.data);
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    if (timeConstant && timeConstant.length > 0) {
      timeConstant &&
        timeConstant.map((time, i) => {
          if (i === 0) {
            updateTime({
              label: time.name,
              value: time.code,
            });
          }
        });
    }
  }, [timeConstant, orderProcessingType]);

  useEffect(() => {
    var tempTimeConstant = [];
    var d = new Date();
    var selectedDate =
      Date.parse(orderProcessingDate) > 0 ? new Date(orderProcessingDate) : d;
    var hour = d.getHours(); //get current hour
    var min = d.getMinutes(); //get current min
    var cdate = d.getDate();
    var cyear = d.getFullYear();
    var cmonth = d.getMonth();

    var interval = 15; //minutes interval
    var times = []; // time array
    var tt = startTime; // start time
    var ap = ["AM", "PM"]; // AM-PM

    //--round time--//
    var date_r = new Date(d.getTime());

    // date_r.setMinutes(((((date_r.getMinutes() + 7.5) / 15) | 0) * 15) % 60);
    date_r.setMinutes(date_r.getMinutes() + interval);
    //date_r.setHours(date_r.setMinutes(date_r.getMinutes() + 8));
    //--end round time--//

    if (selectedDate) {
      //loop to increment the time and push results in array
      for (var i = 0; tt <= 24 * 60; i++) {
        var hh = Math.floor(tt / 60); // getting hours of day in 0-24 format

        const hh_am_pm = hh > 11 ? hh : ((hh + startHour) % 12) + 1;
        var mm = tt % 60; // getting minutes of the hour in 0-55 format
        let final_hh = ("0" + (hh % 12)).slice(-2);
        final_hh = final_hh == 0 ? 12 : final_hh;
        times[i] =
          final_hh +
          ":" +
          ("0" + mm).slice(-2) +
          " " +
          ap[Math.floor(hh_am_pm / 12)]; // pushing data in array in [00:00 - 12:00 AM/PM format]

        tt = tt + interval;

        if (tt <= endTime) {
          //console.log(hh + "=" + hour);
          //console.log(mm + "=" + (min + 15));
          if (
            selectedDate.getDate() == cdate &&
            selectedDate.getMonth() == cmonth &&
            selectedDate.getFullYear() == cyear
          ) {
            //--set time to current option--//
            let timeOption = new Date(d.getTime());
            timeOption.setHours(hh);
            timeOption.setMinutes(mm);

            if (
              //(hh >= hour && (mm >= min + 15 || mm <= min - 30)) ||
              (hh >= hour && timeOption.getTime() > date_r.getTime()) ||
              hour < startHour
            ) {
              tempTimeConstant.push({ code: times[i], name: times[i] });
              // console.log("push:" + times[i]);
            }
          } else {
            tempTimeConstant.push({ code: times[i], name: times[i] });
          }
        }
      }

      setTimeConstant(tempTimeConstant);

      //---Start Override Pickup Time Limits---//
      if (
        enableRevenueCenter == 1 &&
        pickupTimeLimits &&
        menuName &&
        revenueCenters
      ) {
        const tempTimeConstant = [];
        const serviceOption =
          orderProcessingType == 0
            ? "Dine In"
            : orderProcessingType == 1
              ? "Pick Up"
              : "Delivery";

        pickupTimeLimits.forEach((pickupTime) => {
          const { MenuName, OrderType, RevenueCenter, PickUpTime } = pickupTime;

          if (
            MenuName &&
            MenuName.includes(menuName) &&
            OrderType === serviceOption &&
            Object.keys(revenueCenters).includes(RevenueCenter)
          ) {
            tempTimeConstant.push({
              code: PickUpTime,
              name: PickUpTime,
            });
          }
        });

        const sortedTempTimeConstant = tempTimeConstant.sort((a, b) => {
          return (
            new Date("1970/01/01 " + a.code) - new Date("1970/01/01 " + b.code)
          );
        });

        setTimeConstant(sortedTempTimeConstant);
        // console.log(tempTimeConstant, "tempTimeConstant");
      } else {
        if (pickupTimeLimits) {
          const tempTimeConstant = [];
          const serviceOption =
            orderProcessingType == 0
              ? "Dine In"
              : orderProcessingType == 1
                ? "Pick Up"
                : "Delivery";

          pickupTimeLimits.forEach((p) => {
            if (p.OrderType === serviceOption) {
              tempTimeConstant.push({
                code: p.PickUpTime,
                name: p.PickUpTime,
              });
            }
          });

          const sortedTempTimeConstant = tempTimeConstant.sort((a, b) => {
            return (
              new Date("1970/01/01 " + a.code) -
              new Date("1970/01/01 " + b.code)
            );
          });

          setTimeConstant(sortedTempTimeConstant);
        }
      }
      //---End Override Pickup Time Limits---//

      if (
        enableKiosk === 0 &&
        selectedDate.getDate() == cdate &&
        selectedDate.getMonth() == cmonth &&
        selectedDate.getFullYear() == cyear &&
        (hour > endHour || (hour == endHour && min >= endMinutes))
      ) {
        // if (orderProcessingType == 1 || orderProcessingType == 2) {
        //   alert("Store is closed. Pickup & Delivery is for current date only.");
        //   removeDeliveryCharge();
        //   if (enableRevenueCenter == 1) {
        //     history.push("/welcome");
        //   } else {
        //     history.push("/menu");
        //   }
        // } else {
        alert(
          "Store is closed. You can order on the next day when store is open."
        );
        if (enableRevenueCenter == 1) {
          history.push("/welcome");
        } else {
          history.push("/menu");
        }
        // dispatch(
        //   setOrderProcessingDate(
        //     moment(selectedDate, "MM-DD-YYYY").add(1, "days")
        //   )
        // );
        //setDate(selectedDate);
        //}
      }
      if (orderProcessingDate) {
        if (orderProcessingDate && orderTimeSelectValue) return; // If these state has value don't update it
        if (
          selectedDate.getDate() > cdate ||
          (selectedDate.getMonth() == cmonth &&
            selectedDate.getFullYear() == cyear) ||
          selectedDate.getMonth() > cmonth ||
          selectedDate.getFullYear() > cyear
        ) {
          if (orderProcessingType == 0 && siteSettings.FutureDateOnOff == 0) {
            // alert("Dine In is for current date only.");
            dispatch(setOrderProcessingDate(moment(d, "MM-DD-YYYY")));
          }
          if (orderProcessingType == 1 && siteSettings.FutureDateOnOff == 0) {
            // alert("Pickup is for current date only.");
            dispatch(setOrderProcessingDate(moment(d, "MM-DD-YYYY")));
          }
          if (orderProcessingType == 2 && siteSettings.FutureDateOnOff == 0) {
            // alert("Delivery is for current date only.");
            dispatch(setOrderProcessingDate(moment(d, "MM-DD-YYYY")));
          }
        }
      }
    }
  }, [date, siteSettings, pickupTimeLimits, menuName, orderProcessingType]);

  useEffect(() => {
    if (!orderProcessingDate && timeConstant && timeConstant.length < 1) {
      dispatch(setOrderProcessingDate(""));
    }
  }, [orderProcessingDate, orderProcessingType, timeConstant]);

  // let timeConstant = [
  //   { code: "", name: "Select Pickup Time" },
  //   { code: "11:00 am", name: "11:00 am" },
  //   { code: "11:15 am", name: "11:15 am" },
  //   { code: "11:30 am", name: "11:30 am" },
  //   { code: "11:30 am", name: "11:45 am" },
  //   { code: "12:00 pm", name: "12:00 pm" },
  //   { code: "12:15 pm", name: "12:15 pm" },
  //   { code: "12:30 pm", name: "12:30 pm" },
  //   { code: "01:00 pm", name: "01:00 pm" },
  //   { code: "01:15 pm", name: "01:15 pm" },
  //   { code: "01:30 pm", name: "01:30 pm" },
  //   { code: "02:00 pm", name: "02:00 pm" },
  //   { code: "02:15 pm", name: "02:15 pm" },
  //   { code: "02:30 pm", name: "02:30 pm" },
  //   { code: "03:00 pm", name: "03:00 pm" },
  //   { code: "03:15 pm", name: "03:15 pm" },
  //   { code: "03:30 pm", name: "03:30 pm" },
  //   { code: "04:00 pm", name: "04:00 pm" },
  //   { code: "04:15 pm", name: "04:15 pm" },
  //   { code: "04:30 pm", name: "04:30 pm" },
  //   { code: "05:00 pm", name: "05:00 pm" },
  //   { code: "05:30 pm", name: "05:30 pm" },
  //   { code: "06:00 pm", name: "06:00 pm" },
  //   { code: "06:30 pm", name: "06:30 pm" },
  //   { code: "07:00 pm", name: "07:00 pm" },
  //   { code: "07:30 pm", name: "07:30 pm" },
  //   { code: "08:00 pm", name: "08:00 pm" },
  //   { code: "08:30 pm", name: "08:30 pm" },
  //   { code: "09:00 pm", name: "09:00 pm" },
  // ];

  // console.log(orderProcessingDate, "first load");

  const menuNames = cartItems.reduce(function (rv, x) {
    (rv[x["menuName"]] = rv[x["menuName"]] || []).push(x);
    return rv;
  }, {});
  const uniqueMenuNames = [...new Set(Object.keys(menuNames))];

  useEffect(() => {
    uniqueMenuNames.map((menu) => {
      const url =
        "https://ecommv2.servingintel.com/items/item-tag-menu/" +
        serverId +
        "/" +
        siteId +
        "/" +
        menu;
      axios
        .get(url)
        .then((response) => {
          const itemTagMenu = response.data[0];
          if (cartItems.length !== 0 && itemTagMenu) {
            const cleanItemTagMenuCartItems = cartItems.filter(
              (i) => i.comments !== "ItemTagMenu"
            );

            dispatch({
              type: "SET_CART",
              payload: cleanItemTagMenuCartItems,
            });

            dispatch({
              type: "SET_CART",
              payload: [
                ...cleanItemTagMenuCartItems,
                {
                  ...itemTagMenu,
                  quantity: 1,
                  modifiers: [],
                  comments: "ItemTagMenu",
                },
              ],
            });
          }
        })
        .catch((error) => {});
    });
  }, []);

  return (
    <Container id="target">
      <Grid open={open} onClose={() => setOpen(false)} anchor="bottom">
        <Box paddingTop="2rem">
          <Typography
            className="content"
            style={{
              fontWeight: 700,
              fontSize: enableKiosk === "0" ? "1.25rem" : "1.125rem",
            }}
            gutterBottom
          >
            Select Service Option
          </Typography>
          <Typography gutterBottom>
            {`${
              orderProcessingType == 0
                ? "Dine In"
                : orderProcessingType == 1
                  ? "Pickup"
                  : "Delivery"
            } -`}{" "}
            {tableNumber && tableNumber === "none"
              ? moment(orderProcessingDate).format("MMMM DD, YYYY h:mm a") ==
                "Invalid date"
                ? "No time slots for " +
                  menuName +
                  " available. Please remove the items from your cart and choose a different menu."
                : moment(orderProcessingDate).format("MMMM DD, YYYY h:mm a")
              : moment(orderProcessingDate).format("MMMM DD, YYYY")}{" "}
          </Typography>
          <Box height="1rem" />
          <Grid container spacing={2}>
            {enableDineIN == 1 &&
            availableOrderTypes &&
            availableOrderTypes.includes("Dine In") ? (
              <Grid item xs={4}>
                <Box
                  height="75px"
                  // width="100%"
                  display="flex"
                  alignItems="center"
                  borderRadius="10px"
                  justifyContent="center"
                  textAlign="center"
                  border={orderProcessingType != 0 && "1px solid #bebebe"}
                  color={orderProcessingType == 0 && buttonText}
                  bgcolor={orderProcessingType == 0 && buttonColor}
                  onClick={() => {
                    dispatch(setSelectedServiceOption(0));
                    removeDeliveryCharge();
                  }}
                >
                  <Typography className="content">Dine In</Typography>
                </Box>
              </Grid>
            ) : null}
            {enablePickup == 1 &&
            availableOrderTypes &&
            availableOrderTypes.includes("Pick Up") ? (
              <Grid item xs={4}>
                <Box
                  height="75px"
                  // width="100%"
                  display="flex"
                  alignItems="center"
                  borderRadius="10px"
                  justifyContent="center"
                  textAlign="center"
                  border={orderProcessingType != 1 && "1px solid #bebebe"}
                  color={orderProcessingType == 1 && buttonText}
                  bgcolor={orderProcessingType == 1 && buttonColor}
                  onClick={() => {
                    dispatch(setSelectedServiceOption(1));
                    // const d = new Date();
                    // dispatch(setOrderProcessingDate(moment(d, "MM-DD-YYYY")));
                    // setDate(moment(d, "MM-DD-YYYY"));
                    removeDeliveryCharge();
                  }}
                >
                  <Typography className="content">Pickup</Typography>
                </Box>
              </Grid>
            ) : null}
            {enalbeDelivery == 1 &&
            availableOrderTypes &&
            availableOrderTypes.includes("Delivery") ? (
              <Grid item xs={4}>
                <Box
                  height="75px"
                  // width="100%"
                  display="flex"
                  borderRadius="10px"
                  alignItems="center"
                  justifyContent="center"
                  textAlign="center"
                  border={orderProcessingType != 2 && "1px solid #bebebe"}
                  color={orderProcessingType == 2 && buttonText}
                  bgcolor={orderProcessingType == 2 && buttonColor}
                  onClick={() => {
                    dispatch(setSelectedServiceOption(2));
                    // const d = new Date();
                    // dispatch(setOrderProcessingDate(moment(d, "MM-DD-YYYY")));
                    // setDate(moment(d, "MM-DD-YYYY"));
                    addDeliveryCharge();
                  }}
                >
                  <Typography className="content">Room Delivery</Typography>
                </Box>
              </Grid>
            ) : null}
          </Grid>
          <Box height="1rem" />
          <Grid>
            {timeConstant && timeConstant.length > 0 ? (
              <Box>
                <Typography
                  className="content"
                  style={{
                    fontWeight: 600,
                    fontSize: enableKiosk === "0" ? "1.25rem" : "1.125rem",
                  }}
                >
                  Select date
                </Typography>
                <SingleDatePicker
                  className="content"
                  focused={focused}
                  numberOfMonths={1}
                  date={orderProcessingDate}
                  id="date_input"
                  onDateChange={(date) => {
                    if (
                      date === null ||
                      !date.isSame(orderProcessingDate, "day")
                    ) {
                      console.log("Selected date is not the current date.");
                      dispatch(setOrderProcessingDate(orderProcessingDate));
                      setFocused(false);
                      setDate(orderProcessingDate);
                    } else {
                      console.log("Selected date is the current date.");
                      dispatch(setOrderProcessingDate(date));
                      setFocused(false);
                      setDate(date);
                    }
                  }}
                  placeholder="Select date"
                  orientation="horizontal"
                  onFocusChange={(data) => {
                    setFocused(data.focused);
                  }}
                  isDayBlocked={(day) => {
                    const d = new Date(day);
                    return d.getDay() === 0;
                  }}
                />
              </Box>
            ) : null}
          </Grid>
          <Box height="1rem" />

          <Grid container hidden={tableNumber && tableNumber !== "none"}>
            {timeConstant && timeConstant.length > 0 ? (
              <Box width="100%">
                <Typography className="content" style={{ fontWeight: 600 }}>
                  Select time
                </Typography>
                {enableRevenueCenter == 1 &&
                revenueCenters &&
                orderProcessingType >= 0 &&
                orderProcessingType <= 2 ? (
                  Object.keys(revenueCenters).map((item) => {
                    return (
                      <>
                        {item !== "undefined" ? (
                          <>
                            <Typography className="content">
                              {orderProcessingType == 0
                                ? "Dine In at " + item
                                : orderProcessingType == 1
                                  ? "Pickup at " + item
                                  : "Delivery from " + item}
                            </Typography>
                            <Select
                              isSearchable={false}
                              key={JSON.stringify(timeConstant)}
                              label={item}
                              className="content"
                              options={
                                timeConstant &&
                                timeConstant.map((time) => {
                                  const revCounter = pickupCounter.filter(
                                    (p) => Object.keys(p) == item
                                  )[0];

                                  const orderCount =
                                    revCounter && revCounter[item][time.code];

                                  if (pickupTimeLimits) {
                                    const isFoundRevenueCenter =
                                      pickupTimeLimits.some(
                                        (p) => p.RevenueCenter == item
                                      );

                                    let orderLimit;
                                    if (menuName == "none" || menuName == "") {
                                      orderLimit = pickupTimeLimits.filter(
                                        (p) =>
                                          p.RevenueCenter == item &&
                                          String(p.PickUpTime)
                                            .toUpperCase()
                                            .localeCompare(
                                              String(time.code).toUpperCase()
                                            ) == 0
                                      )[0];
                                    } else {
                                      orderLimit = pickupTimeLimits.filter(
                                        (pickupTime) => {
                                          const {
                                            MenuName,
                                            RevenueCenter,
                                            PickUpTime,
                                          } = pickupTime;

                                          return (
                                            MenuName &&
                                            MenuName.includes(menuName) &&
                                            RevenueCenter === item &&
                                            String(PickUpTime).toUpperCase() ===
                                              String(time.code).toUpperCase()
                                          );
                                        }
                                      )[0];
                                    }

                                    if (
                                      orderLimit &&
                                      String(orderLimit.PickUpTime)
                                        .toUpperCase()
                                        .localeCompare(
                                          String(time.name).toUpperCase()
                                        ) == 0
                                    ) {
                                      if (
                                        orderCount &&
                                        orderCount >= orderLimit.AvailableOrders
                                      ) {
                                        return {
                                          label:
                                            time.name +
                                            " Full - No Available Order Slots",
                                          value: "",
                                          isDisabled: true,
                                          rev: "",
                                        };
                                      } else {
                                        return {
                                          label: time.name,
                                          value: time.code,
                                          rev: item,
                                        };
                                      }
                                    } else {
                                      if (pickupTimeLimits.length < 1) {
                                        return {
                                          label: time.name,
                                          value: time.code,
                                          rev: item,
                                        };
                                      } else {
                                        if (isFoundRevenueCenter) {
                                          return {
                                            label: time.name,
                                            value: time.code,
                                            rev: "hidden",
                                            isDisabled: true,
                                          };
                                        } else {
                                          return {
                                            label: time.name,
                                            value: time.code,
                                            rev: item,
                                          };
                                        }
                                      }
                                    }
                                  } else {
                                    return {
                                      label: time.name,
                                      value: time.code,
                                      rev: item,
                                    };
                                  }
                                })
                              }
                              filterOption={(option) => {
                                if (option.data.rev === "hidden") {
                                  return false;
                                } else {
                                  return option;
                                }
                              }}
                              defaultValue={
                                orderTimeSelectValue && {
                                  label: orderTimeSelectValue,
                                  value: orderTimeSelectValue,
                                }
                                //timeConstant.map((time, i) => {
                                //   if (i === 0) {
                                //     const isFullLimit = pickupCounter.filter(
                                //       (p) =>
                                //         Object.keys(p) == item &&
                                //         Object.keys(p).find(
                                //           (i) =>
                                //             String(Object.keys(p[i])[0])
                                //               .toUpperCase()
                                //               .localeCompare(
                                //                 String(time.code).toUpperCase()
                                //               ) === 0 &&
                                //             pickupTimeLimits.find(
                                //               (ptl) =>
                                //                 ptl.RevenueCenter == item &&
                                //                 String(ptl.PickUpTime)
                                //                   .toUpperCase()
                                //                   .localeCompare(
                                //                     String(time.code).toUpperCase()
                                //                   ) === 0
                                //             ) &&
                                //             pickupTimeLimits.find(
                                //               (ptl2) =>
                                //                 ptl2.RevenueCenter == item &&
                                //                 parseInt(p[i][time.code]) ===
                                //                   parseInt(ptl2.AvailableOrders)
                                //             )
                                //         )
                                //     );
                                //     let notAvailableTime;
                                //     if (isFullLimit[0]) {
                                //       notAvailableTime = Object.keys(
                                //         isFullLimit[0][item]
                                //       )[0];
                                //     }

                                //     if (
                                //       isFullLimit[0] &&
                                //       String(notAvailableTime)
                                //         .toUpperCase()
                                //         .localeCompare(
                                //           String(time.code).toUpperCase()
                                //         ) === 0
                                //     ) {
                                //       return {
                                //         label:
                                //           time.name +
                                //           " Full - No Available Order Slots",
                                //         value: "",
                                //         isDisabled: true,
                                //         rev: "",
                                //       };
                                //     } else {
                                //       return {
                                //         label: time.name,
                                //         value: time.code,
                                //         rev: item,
                                //       };
                                //     }
                                //   }
                                // })
                              }
                              styles={{
                                menu: (provided, state) => ({
                                  ...provided,
                                  fontSize: "large",
                                }),

                                singleValue: (provided, state) => ({
                                  ...provided,
                                  fontSize: "large",
                                }),
                              }}
                              onChange={(e) => {
                                setSelectedTime(e);
                                updateTime(e);
                              }}
                            />
                          </>
                        ) : null}
                      </>
                    );
                  })
                ) : (
                  <Select
                    isSearchable={false}
                    className="content"
                    key={JSON.stringify(timeConstant)}
                    options={
                      timeConstant &&
                      timeConstant.map((time) => {
                        return {
                          label: time.name,
                          value: time.code,
                        };
                      })
                    }
                    defaultValue={
                      timeConstant &&
                      timeConstant.map((time, i) => {
                        if (i === 0) {
                          return {
                            label: time.name,
                            value: time.code,
                          };
                        }
                      })
                    }
                    styles={{
                      menu: (provided, state) => ({
                        ...provided,
                        fontSize: "large",
                      }),

                      singleValue: (provided, state) => ({
                        ...provided,
                        fontSize: "large",
                      }),
                    }}
                    onChange={updateTime}
                  />
                )}
              </Box>
            ) : null}
          </Grid>
          <BlankSpace />
          <div></div>
        </Box>
      </Grid>
    </Container>
  );
};

export const TipsDrawerSL = ({ open, setOpen }) => {
  const { totalPriceItems } = useSelector((state) => state.cart);
  const tipsConstant = [0.1, 0.15, 0.2, 0.25];
  const dispatch = useDispatch();
  const initialValues = {
    tip: 0,
  };

  const validationSchema = Yup.object({
    tip: Yup.mixed(),
  });

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const { setValue } = methods;

  const handleSubmit = (d) => {
    // console.log(d);
    dispatch(setTip(d.tip));
    setOpen(false);
  };

  return (
    <Drawer open={open} onClose={() => setOpen(false)} anchor={"bottom"}>
      {parseInt(enableKiosk) === 0 && (
        <FontSizeChanger
          targets={["#target .content"]}
          onChange={(element, newValue, oldValue) => {
            console.log(element, newValue, oldValue);
          }}
          options={{
            stepSize: 2,
            range: 5,
          }}
          customButtons={{
            up: (
              <AppBar
                position="fixed"
                sx={{
                  width: "95px",
                  boxShadow: 0,
                  top: "auto",
                  bottom: 237.5,
                  backgroundColor: "transparent",
                  marginBottom: "50px",
                }}
              >
                <Toolbar>
                  <Box sx={{ flexGrow: 1 }} />
                  <IconButton>
                    <Fab
                      color="primary"
                      aria-hidden="true"
                      sx={{ backgroundColor: "#367c2b" }}
                    >
                      <AddCircleOutlineIcon />
                    </Fab>
                  </IconButton>
                </Toolbar>
              </AppBar>
            ),
            down: (
              <AppBar
                position="fixed"
                sx={{
                  width: "95px",
                  boxShadow: 0,
                  top: "auto",
                  bottom: 180,
                  backgroundColor: "transparent",
                  marginBottom: "50px",
                }}
              >
                <Toolbar>
                  <Box sx={{ flexGrow: 1 }} />
                  <IconButton>
                    <Fab
                      color="primary"
                      aria-hidden="true"
                      sx={{ backgroundColor: "#765F00" }}
                    >
                      <RemoveCircleOutlineIcon />
                    </Fab>
                  </IconButton>
                </Toolbar>
              </AppBar>
            ),
          }}
        />
      )}
      <Box id="target" padding="1rem" height="70vh" position="relative">
        <Typography className="content" style={{ fontWeight: 700 }}>
          Tips
        </Typography>
        <Toolbar />
        <Grid container spacing={2}>
          {tipsConstant.map((tip) => {
            return (
              <Grid key={tip} item xs={3}>
                <Button>
                  <Box
                    display="flex"
                    //border="1px solid black"
                    //borderRadius="50%"
                    justifyContent="center"
                    alignItems="center"
                    padding="0rem"
                    width="60px"
                    height="60px"
                    onClick={() =>
                      setValue("tip", (tip * totalPriceItems).toFixed(2))
                    }
                  >
                    <Typography className="content">{tip * 100} %</Typography>
                  </Box>
                </Button>
              </Grid>
            );
          })}
        </Grid>
        <Toolbar />
        <Form provider={methods} onSubmit={handleSubmit}>
          <Input label="Amount $" name="tip" />
          <Box
            position="absolute"
            bottom="auto"
            left="0px"
            padding="1rem"
            width="100%"
          >
            <Button className="content" color="primary" fullWidth type="submit">
              Apply Tip
            </Button>
          </Box>
        </Form>
      </Box>
    </Drawer>
  );
};

const PaymentSelectorSL = ({
  setOpenUserCards,
  tax,
  pickupTimebyRevenueCenter,
  itemsTotalDiscount,
  getDiscountRate,
  hasDiscountsEnabled,
  selectedTime,
}) => {
  //---From world pay component converted for SL meal balance payment---//
  const {
    totalPriceItems,
    tips,
    cartItems,
    promoCode,
    totalDiscount,
    rewardsBalance,
    giftCardBalance,
    orderSession,
  } = useSelector((state) => state.cart);
  const {
    orderProcessingDate,
    selectedAddress,
    orderProcessingType,
    orderTimeSelectValue,
  } = useSelector((state) => state.public);
  // =========== START ==========
  console.log(orderProcessingType, "TYPE PAYMENTSLEECTOR");
  // =========== END ===========
  const [cookieOrderId, setCookieOrderId, removeCookieOrderId] = useCookies([
    "",
  ]);

  const couponAmount = promoCode ? parseFloat(totalDiscount) : 0.0;
  const subTotal = promoCode
    ? parseFloat(totalPriceItems - itemsTotalDiscount) -
      parseFloat(totalDiscount)
    : parseFloat(totalPriceItems - itemsTotalDiscount);
  const salesTax = Math.round(subTotal * tax * 100 + 1e-10) / 100;
  const total = parseFloat(tips) + parseFloat(subTotal) + parseFloat(salesTax);
  const updatedCartItems = cartItems.map((item) => {
    const discountRate = getDiscountRate(item);
    const discountedPrice = item.DefaultPrice * parseFloat(discountRate / 100);
    return {
      ...item,
      DefaultPrice: item.DefaultPrice - discountedPrice,
    };
  });
  //---End From world pay component converted for SL meal balance payment---//

  const match = useRouteMatch();
  const dispatch = useDispatch();
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "flex-start",
      overflow: "auto",
      backgroundColor: theme.palette.background.paper,
    },
    gridList: {
      flexWrap: "nowrap",
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transform: "translateZ(0)",
    },
    title: {
      color: theme.palette.primary.light,
    },
    titleBar: {
      background:
        "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
    },
  }));

  const classes = useStyles();

  const setOrder = () => {
    let pickupInstruction;
    if (
      enableRevenueCenter == 1 &&
      pickupTimebyRevenueCenter &&
      pickupTimebyRevenueCenter.length > 0
    ) {
      pickupInstruction = " Pickup Instruction: ";
      pickupTimebyRevenueCenter.map((i) => {
        pickupInstruction =
          pickupInstruction + i.revenueCenter + "=" + i.pickup + " ";
      });
    }
    const url =
      "https://ecommv2.servingintel.com/postpayment/setOrder/" +
      siteId +
      "?server_id=" +
      serverId;
    const d = new Date(orderProcessingDate);

    axios
      .post(url, {
        cartItems: hasDiscountsEnabled ? updatedCartItems : cartItems,
        totalPriceItems: subTotal,
        total: total,
        subTotal: subTotal,
        tips: tips,
        tax: tax,
        serviceCharge: orderProcessingType < 2 ? 0.0 : serviceCharge,
        promoCode: promoCode,
        pid: "meal_balance",
        order_id: !sessionStorage.getItem("order_id")
          ? sessionStorage.getItem("order_id")
          : 0,
        orderProcessingType:
          orderProcessingType == 0
            ? dineInDesc
            : orderProcessingType == 1
              ? pickupDesc
              : deliveryDesc,
        orderProcessingDate: {
          date: d.getFullYear() + "/" + (d.getMonth() + 1) + "/" + d.getDate(),
          time: d.getHours() + ":" + d.getMinutes(),
        },
        selectedAddress: selectedAddress,
        instructions: sessionStorage.getItem("instuctions"),
        pickupTimebyRevenueCenter: pickupTimebyRevenueCenter,
        serverName:
          tableNumber && tableNumber != ""
            ? "eServer"
            : enableKiosk === 1
              ? "eKiosk"
              : "eCommerce",
        tableNumber: tableNumber,
      })
      .then((response) => {
        // console.log(response);
        sessionStorage.setItem("order_id", response.data.order_id);
        setCookieOrderId("orderId", response.data.order_id, {
          path: "/",
        });
        sessionStorage.setItem("user_id", response.data.user_id);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const payments = [
    "Meal Credit",
    //"Gift Cards",
    //"Rewards",
  ];

  return (
    <>
      <br />
      <Typography
        style={{
          fontWeight: 700,
          fontSize: enableKiosk === "0" ? "1.25rem" : "1.125rem",
        }}
        gutterBottom
      >
        Type of Payment
      </Typography>

      <div className={classes.root} style={{ "overflow-x": "visible" }}>
        <ImageList
          style={{ width: "auto !important", "overflow-x": "visible" }}
          className={classes.gridList}
          cols={2.2}
        >
          {payments.map((payment) => {
            return (
              <ImageListItem
                className="content"
                key={payment}
                style={{
                  height: "auto",
                  margin: "0rem 6px",
                  padding: "1rem 0rem",
                  width: "auto !important",
                  paddingLeft: "0",
                }}
              >
                <Box
                  style={{
                    cursor: "pointer",
                    backgroundColor: buttonColor,
                    fontSize: enableKiosk === "0" ? "1.25rem" : "1.125rem",
                    color: buttonText,
                    borderColor: buttonColor,
                    width: enableKiosk === "0" ? "inheret" : "200px",
                  }}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  width="100%"
                  height="100px"
                  padding="1rem"
                  border="1px solid #bebebe"
                  borderRadius="5px"
                  onClick={() => {
                    if (
                      cartItems &&
                      cartItems.length > 0 &&
                      orderProcessingDate
                    ) {
                      //Commented because it cause issue during checkout even if time was already selected
                      // if (!selectedTime) {
                      //   Swal.fire({
                      //     icon: "error",
                      //     title: "Oops...",
                      //     text: "Please select a time before submitting your order.",
                      //     showConfirmButton: "OK",
                      //     // timer: 4000,
                      //     // timerProgressBar: true,
                      //     // position: 'bottom-end',
                      //   });
                      //   return;
                      // }
                      setOrder();
                      setOpenUserCards(true);
                      dispatch({
                        type: "SET_SELECTED_MODE_PAYMENT",
                        payload: payment,
                      });
                    } else {
                      if (!orderProcessingDate) {
                        alert("Please provide Service Option details!");
                      } else {
                        alert("Your Check is Empty!");
                      }
                    }
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    textAlign="center"
                  >
                    <Typography>{payment}</Typography>
                    {payment === "Meal Credit" && (
                      <img
                        height="auto"
                        width="100%"
                        alt={payment}
                        title={payment}
                        loading="lazy"
                        src={siteMedSrc + "CARDICONS" + ".svg"}
                        style={{ maxWidth: "70px", margin: "0px" }}
                      />
                    )}
                    {payment === "Gift Cards" && (
                      <img
                        height="auto"
                        width="100%"
                        alt={payment}
                        title={payment}
                        loading="lazy"
                        src={siteMedSrc + "GIFTCARD" + ".png"}
                        style={{ maxWidth: "70px", margin: "0px" }}
                      />
                    )}
                    {payment === "Rewards" && (
                      <img
                        height="auto"
                        width="100%"
                        alt={payment}
                        title={payment}
                        loading="lazy"
                        src={siteMedSrc + "COIN" + ".png"}
                        style={{ maxWidth: "70px", margin: "0px" }}
                      />
                    )}
                  </Box>
                </Box>
              </ImageListItem>
            );
          })}
        </ImageList>
        <PaymentNested />
      </div>
      <BlankSpace />
      <BlankSpace />
    </>
  );
};

export const SelectCreditCardDrawerSL = ({
  open,
  setOpen,
  tax,
  itemsTotalDiscount,
  discount,
}) => {
  const [userCards, setUserCards] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [firstName, setFirstName] = useState(
    sessionStorage.getItem("firstName")
  );
  const [roomNumber, setRoomNumber] = useState(
    sessionStorage.getItem("roomNumber")
  );
  const [mealBalance, setMealBalance] = useState();
  const [mealBalanceDollars, setMealBalanceDollars] = useState();
  const mBal = Math.abs(mealBalanceDollars / 100);
  const mBalance = mBal.toFixed(2);
  const { user } = useSelector((state) => state.public);
  const { selectedPayment } = useSelector((state) => state.cart);
  const { orderTimeSelectValue } = useSelector((state) => state.public);
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();
  const loginCustomerNumber = process.env.REACT_APP_LOGIN_CUSTOMER_NUMBER;

  useEffect(() => {
    const url =
      loginCustomerNumber == 1
        ? "https://ecommv2.servingintel.com/customer/login-customer-number/" +
          serverId +
          "/" +
          siteId +
          "/" +
          firstName +
          "/" +
          roomNumber
        : "https://ecommv2.servingintel.com/customer/login/" +
          serverId +
          "/" +
          siteId +
          "/" +
          storeId +
          "/" +
          firstName +
          "/" +
          roomNumber;
    axios
      .get(url)
      .then((response) => {
        const customer = response.data;
        if (
          customer.FirstName.toLowerCase() === firstName.toLowerCase() &&
          (customer.Address1.toLowerCase() === roomNumber.toLowerCase() ||
            customer.CustomerNumber == roomNumber)
        ) {
          setMealBalance(customer.MealCount);
          setMealBalanceDollars(
            customer.CreditLimit - customer.AvailableCredit
          );
          //change MealDollrar to Mealcount
          dispatch(
            setSelectedAddress({
              first_name: customer.FirstName,
              last_name: customer.LastName,
              phone: customer.Phone,
              email: customer.Email ? customer.Email : "",
              address: customer.Address1,
              address2: customer.Address2,
              city: customer.City,
              zipCode: customer.Zip,
            })
          );
        }
      })
      .catch((error) => {});
  }, []);

  const sendOrder = () => {
    setLoading(true);
    const url =
      "https://ecommv2.servingintel.com/postpayment/send_econnect_sl/" +
      serverId +
      "/" +
      siteId +
      "/" +
      storeId +
      "/" +
      sessionStorage.getItem("order_id");
    axios
      .get(url)
      .then((response) => {
        setLoading(false);
        if (response.data) {
          localStorage.clear();
          sessionStorage.removeItem("order_id");
          sessionStorage.removeItem("table");

          toast.success(`Your Order has been Completed! Thank You.`);
          if (enableRevenueCenter == 1) {
            history.push("/welcome");
          } else {
            history.push("/menu");
          }
        } else {
          console.log(response);
        }
      })
      .catch((error) => {});
  };

  return (
    <Drawer open={open} onClose={() => setOpen(false)} anchor="bottom">
      {parseInt(enableKiosk) === 0 && (
        <FontSizeChanger
          targets={["#target .content"]}
          onChange={(element, newValue, oldValue) => {
            console.log(element, newValue, oldValue);
          }}
          options={{
            stepSize: 2,
            range: 5,
          }}
          customButtons={{
            up: (
              <AppBar
                position="fixed"
                sx={{
                  width: "95px",
                  boxShadow: 0,
                  top: "auto",
                  bottom: 237.5,
                  backgroundColor: "transparent",
                  marginBottom: "50px",
                }}
              >
                <Toolbar>
                  <Box sx={{ flexGrow: 1 }} />
                  <IconButton>
                    <Fab
                      color="primary"
                      aria-hidden="true"
                      sx={{ backgroundColor: "#367c2b" }}
                    >
                      <AddCircleOutlineIcon />
                    </Fab>
                  </IconButton>
                </Toolbar>
              </AppBar>
            ),
            down: (
              <AppBar
                position="fixed"
                sx={{
                  width: "95px",
                  boxShadow: 0,
                  top: "auto",
                  bottom: 180,
                  backgroundColor: "transparent",
                  marginBottom: "50px",
                }}
              >
                <Toolbar>
                  <Box sx={{ flexGrow: 1 }} />
                  <IconButton>
                    <Fab
                      color="primary"
                      aria-hidden="true"
                      sx={{ backgroundColor: "#765F00" }}
                    >
                      <RemoveCircleOutlineIcon />
                    </Fab>
                  </IconButton>
                </Toolbar>
              </AppBar>
            ),
          }}
        />
      )}
      <Modal
        show={showModal}
        style={{ zIndex: "99999" }} // corrected style object
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-container"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Success!!!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Typography
            style={{
              fontWeight: 700,
              fontSize: enableKiosk === "0" ? "1.25rem" : "1.125rem",
            }}
            component="h4"
            variant="subtitle1"
          >
            Your Order has been Completed! Thank You.
          </Typography>
        </Modal.Body>
        <Modal.Footer>
          <Grid container justifyContent="center" className="content">
            <Button
              fullWidth
              color="primary"
              size="large"
              type="submit"
              variant="contained"
              style={{
                textTransform: "none",
                padding: "0rem 1rem",
                borderRadius: "10px",
                border: "1px solid #bebebe",
                backgroundColor: buttonColor,
                fontSize: "large",
                width: "50%",
              }}
              onClick={() => {
                setOpen(false);
                setShowModal(false);
                // setTimeout(() => {
                //   if (enableRevenueCenter == 1) {
                //     history.push("/welcome");
                //   } else {
                //     history.push("/menu");
                //   }
                // }, 500);
              }}
            >
              OK
            </Button>
          </Grid>
        </Modal.Footer>
      </Modal>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box id="target" padding="1rem" minHeight="80vh" position="relative">
        <Box height="40px" />
        <Typography
          fontSize={enableKiosk === "0" ? "1.25rem" : "1.125rem"}
          className="content"
          component="div"
          align="center"
        >
          Hey {firstName}, your
        </Typography>
        <Typography
          fontSize={enableKiosk === "0" ? "1.25rem" : "1.125rem"}
          className="content"
          style={{ fontWeight: 700, "text-align": "center" }}
        >
          {/*selectedPayment*/}
        </Typography>
        {userCards.map((card) => {
          return (
            <Box
              borderRadius="10px"
              border="1px solid #bebebe"
              padding="1rem 1rem"
              margin="1rem 0rem"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              overflow="auto"
              onClick={() => {
                dispatch(setModeOfPayment(card));
                setOpen(false);
              }}
            >
              <Typography className="content">{card.MaskedAccount}</Typography>
            </Box>
          );
        })}

        {selectedPayment == "Meal Credit" && (
          <div>
            <Typography component="div" align="center">
              <b>Meal Credit Available: </b>
              <b>{mealBalance}</b>{" "}
            </Typography>
            <Typography component="div" align="center">
              <b>Current Balance: </b>
              <b>${mBalance}</b>{" "}
            </Typography>
            <br></br>
            <Grid container justifyContent="center" className="content">
              <Button
                fullWidth
                color="primary"
                size="large"
                type="submit"
                variant="contained"
                style={{
                  textTransform: "none",
                  padding: "0rem 1rem",
                  borderRadius: "10px",
                  border: "1px solid #bebebe",
                  backgroundColor: buttonColor,
                  fontSize: "large",
                  width: "50%",
                }}
                onClick={() => {
                  sendOrder();
                }}
              >
                Submit Order
              </Button>
            </Grid>
          </div>
        )}
      </Box>
    </Drawer>
  );
};
